.blankDiv {
  background: #545454;
  height: 100px;
  width: 100%;
}
.salePageContainer {
  /* background: rgba(0, 47, 103, 1); */
  background: linear-gradient(152deg, #002f67 6.66%, #001631 97.36%);
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSaleBox {
  width: 40%;
  padding: 1vw;
}

.smallerText1 {
  font-size: 2vw;
  font-weight: 700;
  color: white;
  margin-top: 10px;
  text-align: justify;
}

.smallerText2 {
  font-size: 2.5vw;
  font-weight: 700;
  color: white;
  margin-top: 10px;
  text-align: justify;
}

.rightTeam {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightTeam > img {
  width: 100%;
  object-fit: contain;
  height: 90%;
  border-radius: 0.5px;
}

.buttonCon {
  color: black;
  background: #ffc95b;
  padding: 1vw 3vw;
  font-size: 1.5vw;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 1.5vw;
  margin-top: 20px;
  text-align: justify;
}
.album {
  display: flex;
  justify-content: center;
  padding: 2vw;
  gap: 2vw;
  height: 40vh;
  width: 100%;
  overflow: scroll;
}

.album > div {
  flex-grow: 1;
  height: 100%;
}

.album > div > img {
  height: 100%;
  object-fit: contain;
  border-radius: 0.5vw;
}




@media only screen and (max-width: 768px) {
  .blankDiv {
    background-color: transparent;
  }
  .salePageContainer {
    height: auto;
    flex-direction: column-reverse;
  }

  .leftSaleBox {
    width: 100%;
    padding: auto;
    text-align: center;
  }

  .smallerText1 {
    font-size: 5vw;
    text-align: center;
  }
  .smallerText2 {
    font-size: 5.5vw;
    text-align: center;
  }

  .rightTeam {
    height: auto;
    width: 100%;
  }
  .rightTeam > img {
    width: 90%;
    height: auto;
    border-radius: 2vw;
    margin-top: -4vh;
  }

  .buttonCon {
    color: rgb(32, 22, 161);
    padding: 2vw 5vw;
    font-size: 3vw;
    font-weight: 900;
    border-radius: 3vw;
    margin: 30px;
  }
  .album {
    padding: 5vw;
    gap: 3vw;
    height: 30vh;
  }

  .album > div {
    flex-grow: 1;
    height: 100%;
  }

  .album > div > img {
    height: 100%;
    object-fit: contain;
    border-radius: 0.5vw;
  }
}
