.mainContainer {
  width: 100vw;
  height: 47vw;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url("../../Assets/team.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.contentContainer {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  background: linear-gradient(
    180deg,
    rgba(0, 47, 103, 0) 0%,
    rgba(0, 47, 103, 0.71) 76.48%,
    #002f67 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}
.leftContentBox {
  width: 40%;
  padding: 1vw;
}

.brandDesc {
  font-size: 1.8vw;
  color: #fff;
  font-weight: 700;
}
.brandDesc > span {
  color: #ffc95b;
}

.admissionButton {
  color: #002f67;
  text-align: center;
  background: white;
  border-radius: 0.5vw;
  padding: 1vw;
  font-size: 1vw;
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: capitalize;
}
.classText {
  color: #ffc95b;
  font-size: 1vw;
  font-weight: 700;
}

.rightContentBox {
  width: 30%;
  background: white;
  padding: 1vw 2vw;
  border-radius: 0.5vw;
  box-shadow: 1.198px 0px 8.986px 0px rgba(182, 182, 182, 0.15);
}

.enquiryText {
  color: black;
  text-align: center;
  font-size: 1.5vw;
  font-weight: 700;
}

.dropdownForGrade {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0.5vw;
  border: 0.1vw solid var(--black, #252525);
}

.dropdownForGrade > select {
  height: 100%;
  padding: 1vw;
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.2vw;
  color: rgba(83, 83, 83, 0.5);
  border: none;
  outline: none;
}
.submitButtonEnquiry {
  width: 100%;
  background: var(--black, #252525);
  color: white;
  cursor: pointer;
  font-size: 1.3vw;
  font-weight: 700;
  border-radius: 0.5vw;
  outline: none;
  border: none;
  padding: 1vw;
}
.otpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5vw;
  border: 0.1vw solid var(--black, #252525);
}
.indianFlagCont {
  display: flex;
  align-items: center;
  font-size: 1.2vw;
  font-weight: 700;
  height: 100%;
  gap: 0.1vw;
}

.indianFlagCont > img {
  height: 100%;
  object-fit: contain;
}
.indianFlagCont > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.inputContainer1 {
  /* height: 50px; */
  /* margin-top: 15px;
  margin-bottom: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputContainer1 > input {
  height: 100%;
  padding: 1vw;
  border: none;
  /* border-radius: 0.5vw; */
  border-left: 0.1vw solid var(--black, #b9b7b7);
  border-right: 0.1vw solid var(--black, #b9b7b7);
  width: 100%;
  font-size: 1.2vw;
  color: rgba(83, 83, 83, 0.5);
  outline: none;
}
.sendOtpBtn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  border-radius: 0.5vw;
  background: #002f67;
  padding: 0.1vw;
  height: 50px;
  flex-grow: 1;
}
.sendOtpBtn > button {
  color: #fff;
  text-align: center;
  border-radius: 0.5vw;
  background: transparent;
  padding: 0.1vw;
  height: 100%;
  border: none;
  font-size: 1vw;
  font-family: 700;
  padding: 0.2vw;
  cursor: pointer;
}

/* Input field text color */
input,
select {
  color: black !important; /* Makes the text color black */
}

/* Placeholder color for input and select fields */
input::placeholder,
select {
  color: #9e9e9e; /* Matches the placeholder shade */
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    background-position: top;
    height: auto;
  }
  .contentContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.65);
    gap: 5vh;
    height: auto;
    padding: 3vw;
  }
  .brandDesc {
    font-size: 4vw;
  }

  .leftContentBox {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .admissionButton {
    font-size: 2.7vw;
    /* width: 70%; */
    text-align: center;
  }
  .classText {
    font-size: 3vw;
  }

  /* here  */

  .rightContentBox {
    width: 90%;
    padding: 2vw 4vw;
    border-radius: 1vw;
  }
  .enquiryText {
    font-size: 4vw;
  }

  .dropdownForGrade {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2vw;
  }

  .dropdownForGrade > select {
    padding: 2vw;
    border-radius: 2vw;
    font-size: 3.5vw;
  }
  .submitButtonEnquiry {
    font-size: 3vw;
    border-radius: 2vw;
    padding: 2vw;
  }
}
