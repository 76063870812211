.footerContainer {
  background: #002f67;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh 0;
}

.contentWrapper {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: flex-start;
}

.leftFooter,
.rightFooter {
  width: 48%; /* Give both sections nearly half width */
}

.footerLogoBox {
  margin-bottom: 1vh;
}

.footerLogoBox > img {
  height: 10vh;
  object-fit: contain;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  color: white;
  font-size: 1rem;
  margin-top: 30px;
}

.locationBox,
.phoneBox {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.locationBox > img,
.phoneBox > img {
  height: 2.5vw;
  padding: 0.5vw;
  background-color: white;
  border-radius: 50%;
}

.locationBox div {
  word-wrap: break-word; /* Ensures text wraps if it exceeds container width */
  line-height: 1.5;
}

.phoneText {
  text-decoration: none;
  color: white;
}

.copyRightDiv {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  padding: 2vh 0;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.25rem; /* Equivalent to Tailwind mt-5 */
  margin-bottom: 1.25rem; /* Equivalent to Tailwind mb-5 */
}

.heading {
  color: white;
  font-weight: 600; /* Equivalent to Tailwind font-semibold */
  font-size: 1.25rem; /* Equivalent to Tailwind text-xl */
  margin-bottom: 0.4rem; /* Equivalent to Tailwind mb-4 */
}

.iconContainer {
  display: flex;
  gap: 1rem; /* Equivalent to Tailwind gap-4 */
}

.iconWrapper {
  border-radius: 50%; /* Equivalent to Tailwind rounded-full */
  transition: background-color 0.3s;
}

.iconWrapper:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }

  .leftFooter,
  .rightFooter {
    width: 100%; /* Full width for smaller screens */
    text-align: start;
  }

  .footerLogoBox > img {
    height: 8vh;
  }

  .copyRightDiv {
    font-size: 3vw;
  }

  .locationBox > img,
  .phoneBox > img {
    height: 6vw;
    padding: 1vw;
    margin-right: 5px;
    background-color: white;
    border-radius: 50%;
  }
}
