.whoWeAreContainer {
  height: 100vh;
  background-color: #545454;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}
.leftWhoCont {
  width: 45%;
  text-align: justify;
  padding: 1vw;
}

.topleftTextBox {
  font-size: 2vw;
  font-weight: 700;
  color: black;
}

.topleftTextBox span {
  color: #002f67;
}

.belowSmallText {
  color: #131313;
  font-size: 1.2vw;
  font-weight: 500;
  text-align: justify;
}

.rightWhoCont {
  width: 45%;
  padding: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  animation: zoomOut 1.5s ease-in-out 1 forwards;
}
.studentBox {
  display: flex;
  flex-direction: column;
  gap: 4vh;
}
.studentBox > img {
  width: 20vw;
  object-fit: contain;
}
.studentBox > div {
  background-color: white;
  text-align: center;
  font-size: 1.5vw;
  font-weight: 700;
  padding: 1.3vw;
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (max-width: 768px) {
  .whoWeAreContainer {
    flex-direction: column-reverse;
    gap: 2vw;
    height: auto;
  }
  .leftWhoCont {
    width: 90%;
    padding: 2vw;
  }

  .topleftTextBox {
    font-size: 3.5vw;
    text-align: center;
  }

  .topleftTextBox span {
    color: #002f67;
  }

  .belowSmallText {
    font-size: 3.5vw;
    font-weight: 700;
  }
  .belowSmallText > b {
    color: #002f67;
  }
  .rightWhoCont {
    width: 90%;
    padding: 3vh 1vw;
  }
  .studentBox {
    gap: 1vh;
    width: 100%;
  }
  .studentBox > img {
    width: 100%;
    object-fit: contain;
  }
  .studentBox > div {
   
    font-size: 2vw;
    font-weight: 700;
    padding: 1.5vw;
  }
}
