.formContainer {
  margin: 20px 0px;
}

.enquiryText {
  color: white;
  font-size: 1.5vw;
  font-weight: 700;
}

.dropdownForGrade {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0.5vw;
  border: 0.1vw solid var(--black, #252525);
}

.dropdownForGrade > select {
  height: 100%;
  padding: 1vw;
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.2vw;
  color: rgba(83, 83, 83, 0.5);
  border: none;
  outline: none;
}
.submitButtonEnquiry {
  width: 100%;
  background: #b4d5ff;
  color: white;
  cursor: pointer;
  font-size: 1.3vw;
  font-weight: 700;
  border-radius: 0.5vw;
  outline: none;
  border: none;
  padding: 1vw;
}

.submitText {
  color: #1b2124;
}

/* Input field text color */
input,
select {
  color: black !important; /* Makes the text color black */
}

/* Placeholder color for input and select fields */
input::placeholder,
select {
  color: #9e9e9e; /* Matches the placeholder shade */
}

@media only screen and (max-width: 768px) {
  .enquiryText {
    font-size: 4vw;
  }

  .dropdownForGrade {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2vw;
  }

  .dropdownForGrade > select {
    padding: 2vw;
    border-radius: 2vw;
    font-size: 3.5vw;
  }
  .submitButtonEnquiry {
    font-size: 3vw;
    border-radius: 2vw;
    padding: 2vw;
  }
}
