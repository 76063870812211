.mainContainerSchool {
  background: #ecf5ff;
  min-height: 100vh;
  width: 100vw;
  padding-top: 2vw;
}
.topLineBarBox {
  text-align: center;
  font-size: 1.4vw;
  color: black;
  font-weight: 600;
}
.topLineBarBox span {
  color: #002f67;
}

.topLineDetailedBar {
  font-size: 1.2vw;
  text-align: center;
  width: 80%;
  margin: auto;
  padding: 2vw;
  font-weight: 500;
  color: black;
}

.singleBoxCont {
  gap: 3vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80vw;
  margin: auto;
  padding: 2vw 0;
  justify-items: center;
}

.singleContainerB {
  height: 20vw;
  width: 20vw;
  position: relative;
  border-radius: 0.5vw;
  background-size: contain;
}

.innerTextBox {
  position: absolute;
  bottom: 0;
  color: white;
  font-size: 1.2vw;
  font-weight: 600;
  padding: 1.5vw;
  background: #002f67;
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  cursor: pointer;
  height: fit-content;
}

.singleContainerB:hover .innerTextBox {
  height: 100%;
  animation: heightInc 1s linear;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}
.descTextCont {
  padding-top: 2vw;
}

@keyframes heightInc {
  from {
    height: 20%;
  }
  to {
    height: 100%;
  }
}
.descTextCont {
  display: none;
}

.singleContainerB:hover .descTextCont {
  display: block;
}
.buttonCon {
  color: rgb(12, 19, 82);
  background: #ffc95b;
  padding: 1vw 3vw;
  font-size: 1.5vw;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 1.5vw;
  margin-top: 20px;
  text-align: justify;
  font-family: 900;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .buttonCon {
    color: rgb(32, 22, 161);
    padding: 2vw 5vw;
    font-size: 3vw;
    font-weight: 900;
    border-radius: 3vw;
    margin: 30px;
  }

  .mainContainerSchool {
    min-height: auto;
    padding-top: 2vh;
  }
  .topLineBarBox {
    font-size: 3vw;
    font-weight: 600;
  }

  .topLineDetailedBar {
    font-size: 3vw;
    width: 90%;
    font-weight: 700;
  }

  .imageBoxCont1 {
    padding: 1vw;
  }

  .singleBoxCont {
    gap: 4vw;
    grid-template-columns: repeat(2, 1fr);
    width: 100vw;
    padding: 2vh 1vw;
    justify-items: center;
  }

  .singleContainerB {
    height: 40vw;
    width: 40vw;
    /* border-radius: 1vw; */
  }

  .innerTextBox {
    font-size: 3vw;
    padding: 2vw;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
  }

  .singleContainerB:hover .innerTextBox {
    height: 100%;
    animation: heightInc 1s linear;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
  }
  .descTextCont {
    padding-top: 2vw;
  }

  @keyframes heightInc {
    from {
      height: 20%;
    }
    to {
      height: 100%;
    }
  }
  .descTextCont {
    display: none;
  }

  .singleContainerB:hover .descTextCont {
    display: block;
  }
}
