.advertisement {
  overflow: hidden;
  height: 42px;
  background: #ffc95b;
  display: flex;
  align-items: center;
  color: #252525;
  font-size: 1.1vw;
  font-weight: 700;
  padding: 10px;
}

.scrolling-text {
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  display: inline-block;
  margin: 1vw;
}

@keyframes marquee {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 768px) {
    .advertisement {
        font-size: 4.5vw;
      }
}

