.schoolOverViewContainer {
  width: 100vw;
  /* background-color: rgb(0, 0, 0, 0.8); */
  background-color: rgba(2, 6, 12, 0.9);
  /* background-image: url("../../Assets/team.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
}
.upperBox1 {
  display: flex;
  padding: 3vw;
  padding-top: 5vw;
  justify-content: space-between;
}
.left1 {
  width: 55%;
}
.right1 {
  width: 40%;
  display: flex;
}
.right1 img {
  object-fit: contain;
  height: 40%;
  border: 1px solid white;
  background-color: #002f67;
  padding: 2vw;
}

.largerText {
  color: white;
  font-weight: 700;
  font-size: 2.7vw;
}
.smallerText {
  color: white;
  font-size: 1.4vw;
  text-align: justify;
  font-weight: 500;
}
.smallLine {
  height: 100px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smallLine > div {
  border: 0.1vw solid rgb(189, 189, 53);
  width: 100%;
}

.bottomBox1 {
  display: flex;
  padding: 3vw;
  justify-content: space-between;
  width: 100vw;
}

.eachCont {
  border: 1px solid white;
  width: 27%;
  height: 200px;
  border-radius: 1vw;
  padding: 1vw;
}

.acImages {
  height: 60px;
  object-fit: contain;
}
.tagLineImg {
  color: white;
  word-wrap: break-word;
  margin-top: 20px;
}
.artFacilities {
  width: 100%;
  display: flex;
  height: 100px;
  position: relative;
  background: #545454;
  background-color: white;
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35); */
}
.artFacilities > p {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 2vw;
  font-weight: 500;
  padding-bottom: 2vw;
}
@media only screen and (max-width: 768px) {
  .schoolOverViewContainer {
    background-color: #002f67;
    padding-bottom: 5vh;
  }
  .upperBox1 {
    padding: 3vw;
    padding-top: 2vh;
    /* justify-content: space-between; */
    flex-direction: column;
    /* align-items: center; */
  }
  .left1 {
    width: 100%;
    text-align: center;
    margin-top: 2vh;
  }
  .right1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
  }
  .right1 img {
    height: 50px;
    padding: 2vw;
  }

  .largerText {
    font-size: 3vw;
  }
  .smallerText {
    font-size: 3vw;
    text-align: center;
  }
  .smallLine {
    height: 50px;
    width: 40px;
  }

  .bottomBox1 {
    padding: 3vw;
    /* width: 100vw; */
    flex-direction: column;
    align-items: center;
  }

  .eachCont {
    border: 1px solid white;
    width: 80%;
    height: 180px;
    border-radius: 2vw;
    padding: 3vw;
    background: rgba(97, 97, 97, 0.3);
    margin-top: 2vh;
  }

  .acImages {
    height: 50px;
  }
  .tagLineImg {
    color: white;
    word-wrap: break-word;
    margin-top: 20px;
  }
  .artFacilities {
    background: white;
  }
  .artFacilities > p {
    font-size: 4vw;
    font-weight: 700;
  }
}
