.mainContainer {
  background: #082f67;
  height: 90px;
  width: 100vw;
  padding: 0.5vw 5vw;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.leftContainer > img {
  height: 100%;
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.phoneBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  border-radius: 50%;
  background: #1f84fe;
  box-shadow: 0px 5.031px 30.185px 0px #0073ff;
  padding: 0.8vw;
  box-sizing: border-box;
}

.phoneBox > img {
  height: 100%;
}

.phoneBox1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000%;
  color: white;
  font-size: 1.6vw;
  font-weight: 600;
}
.Dropdown1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropdown1 > select {
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
}
.bottomDrop {
  display: none;
}

.phoneText {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .phoneBox1 {
    display: none;
  }
  .phoneBox {
    padding: 2vw;
  }
  .bottomDrop {
    display: block;
    margin-bottom: 15px;
  }
  .dropDownCenter {
    display: none;
  }
}
