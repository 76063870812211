.inputContainer {
  /* height: 50px; */
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputContainer > input {
  height: 100%;
  padding: 1vw;
  border-radius: 0.5vw;
  border: 0.1vw solid var(--black, #252525);
  width: 100%;
  font-size: 1.2vw;
  color: rgba(83, 83, 83, 0.5);
}
@media only screen and (max-width: 768px) {
  .inputContainer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .inputContainer > input {
    padding: 2vw;
    border-radius: 2vw;
    border: 0.1vw solid var(--black, #252525);
    font-size: 3.5vw;
  }

}