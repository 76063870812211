.mainBoxOfDetailsPage {
  position: relative;
  /* overflow: auto;
  max-height: 100vh; */
}
.mainBoxOfDetailsPage > div:nth-child(even) {
  background: #ecf5ff;
}

.eachContainerBox {
  padding: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  gap: 10vw;
}
.imgContainer {
  width: 35%;
}
.imgContainer > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.boxText {
  width: 35%;
  font-weight: 700;
}

.boxText > h2 {
  color: #002f67;
  font-size: 2vw;
  font-weight: 700;
  /* text-align: justify; */
  text-transform: uppercase;
}

.boxText > p {
  color: #333;
  font-size: 1.2vw;
  /* text-align: justify; */
}

.arrowDiv {
  height: 100%;
  width: 100%;
  background-color: transparent !important;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
}
.arrowDiv > div {
  height: 100%;
  /* width: 10px; */
  border-left: 1.5px dashed rgb(221, 221, 230);
  border-right: 1.5px dashed rgb(221, 221, 230);
  display: flex;
  justify-content: center;
  position: relative;
}

.innerDivOfArrow {
  width: 3px;
  height: 500px;
  background-color: #002f67;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: end;
}
.arrowSign {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #002f67;
  transform: rotate(180deg);
  margin-bottom: -10px;
}
@media only screen and (max-width: 768px) {
  .mainBoxOfDetailsPage > div:nth-child(even) {
    flex-direction: column-reverse;
    gap: 7vh;
  }

  .eachContainerBox {
    padding: 2vw;
    height: auto;
    gap: 7vh;
    flex-direction: column;
  }
  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .imgContainer > img {
    height: auto;
    width: 60%;
    object-fit: contain;
  }
  .boxText {
    width: 60%;
  }

  .boxText > h2 {
    font-size: 3vw;
  }

  .boxText > p {
    font-size: 3vw;
    /* text-align: justify; */
  }
  .arrowDiv > div {
    justify-content: start;
    margin-left: 50px;
  }
 
  
}
